import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addTache = ( tache ) => {
  return axios.post(API_URL + "/tache/" , tache ,{ headers: authHeader() });
};

const gedTaches = () => {
  return axios.get(API_URL + "/tache/" , { headers: authHeader() });
};

const updateTache = (id , tache ) => {
  return axios.put(API_URL + "/tache/" + id , tache, { headers: authHeader() });
};

const deleteTache = (id) => {
  return axios.delete(API_URL + "/tache/" + id , { headers: authHeader() });
};


const updateTaskStatus = (taskId, etat) => {
  return axios.put(API_URL + "/tache/status/" + taskId , {etat} ,  { headers: authHeader() })
}

const exportsMethodes = {
  addTache,
  gedTaches,
  updateTache,
  deleteTache,
  updateTaskStatus
};
export default exportsMethodes;