import { EditOutlined, HighlightOutlined, ToolOutlined } from "@ant-design/icons";
import {
  Button, Card, Col, Divider, Empty, Form, Input, InputNumber, message,
  Modal, Row, Select, Spin, Table, Tabs, Tag, Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import typeBroderie from "../../../../helpers/typeBroderie";
import AssemblageService from "../../../../services/assemblage.service";

import BroderieService from "../../../../services/broderie.service";

import OrdreCoupeService from "../../../../services/ordreCoupe.service";
import typeCoupeService from "../../../../services/typeCoupe.service";
import typeTissusService from "../../../../services/typeTissus.service";


import referenceBroderieService from "../../../../services/referenceBroderie.service";
import clientService from "../../../../services/client.service";
import fournisseurService from "../../../../services/fournisseur.service";
import produitService from "../../../../services/produit.service";



import Can from "../../../../security/Can";
  
import moment from "moment";
import 'moment/locale/fr'
moment.locale('fr')

  const Assemblage = () => {
    const [assemblageForm] = Form.useForm();

    const [assemblageModal, setAssemblageModal] = useState(false);
    const [controleQualiteModal, setControleQualiteModal] = useState(false);
    const [productionModal, setProductionModal] = useState(false);

    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [updateElement, setUpdateElement] = useState();
    const [updateType, setUpdateType] = useState();
    const [objectifMax, setObjectifMax] = useState(0);



    const [dataTable, setDataTable] = useState([]);

    const [typeCoupe, setTypeCoupe] = useState([]);
    const [typeTissu, setTypeTissu] = useState([]);
    
    const [selectedType , setSelectedType] = useState(false);
    const [referencesBroderies, setReferencesBroderie] = useState([]);
    const [produits, setProduits] = useState([]);
    const [clients, setClients] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);

    const [isStateChange, setStateChange] = useState(0);
    

    useEffect(() => {
      loadAssemblages();
      loadTypeCoupes();
      loadTypeTissus();
      
      loadProduits();
      loadReferencesBrodrie();
      loadFournisseurs();
      loadClients();


      return () => {
        clearStates();
      };
    }, []);
  
    const clearStates = () => {
      setDataTable([]);
      setEditMode(false);
      setUpdateId(null);
    };

    const loadAssemblages = async () => {
      setLoading(true);
      const response = await AssemblageService.getAssemblages();
      const tCoup = await typeCoupeService.getTypeCoupes();
      const tTissus = await typeTissusService.getTypeTissus();
      if (response.status === 200 || 204) initTableData(response.data,tCoup.data, tTissus.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };

    const loadTypeCoupes = async () => {
        setLoading(true);
        const response = await typeCoupeService.getTypeCoupes();
        if (response.status === 200 || 204) setTypeCoupe(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };
    const loadTypeTissus = async () => {
        setLoading(true);
        const response = await typeTissusService.getTypeTissus();
        if (response.status === 200 || 204) setTypeTissu(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };

    const loadReferencesBrodrie = async () => {
      setLoading(true);
      const response = await referenceBroderieService.getReferenceBroderies();
      if (response.status === 200 || 204) setReferencesBroderie(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };
    const loadFournisseurs = async () => {
      setLoading(true);
      const response = await fournisseurService.getFournisseurs();
      if (response.status === 200 || 204) setFournisseurs(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };

    const loadClients = async () => {
      setLoading(true);
      const response = await clientService.getClients();
      if (response.status === 200 || 204) setClients(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };

    const loadProduits = async () => {
      setLoading(true);
      const response = await produitService.getProduitFini();
      if (response.status === 200 || 204) setProduits(response.data);
      else message.error("Une erreur est survenu ! ");
      setLoading(false);
    };


    const initTableData = (data, tCoup, tTissus) => {
      for (const element of data) {
        element.showType = element.type === 1 ? "Suite Bon Commande" : "Interne"
        element.bonCommande = element?.bon_commande_vente?.reference ?? "N/A"
        element.showProduit = element?.produit?.libelle;

        element.typeOrdreCoupe = element?.prod_type_ordre_coupe?.nom;
        element.composantes = [{
          typeComposante: "Face",
          qteTotale: element.qteFaceRemoveStockBroderie + element.qteFaceRemoveStockCoupe,
          qteValide: element.qteFaceValide,
          qteRetourCoupe: element.qteFaceRetourStockCoupe,
          qteRetourBroderie: element.qteFaceRetourStockBroderie,
        },{
          typeComposante: "Dos",
          qteTotale: element.qteDosRemoveStockCoupe + element.qteDosRemoveStockBroderie,
          qteValide: element.qteDosValide,
          qteRetourCoupe: element.qteDosRetourStockCoupe,
          qteRetourBroderie: element.qteDosRetourStockBroderie,
        },{
          typeComposante: "Manches",
          qteTotale: element.qteManchesRemoveStockCoupe,
          qteValide: element.qteManchesValide,
          qteRetourCoupe: element.qteManchesRetourStockCoupe,
          qteRetourBroderie: 0,
        }];

        element.qteCorrection = Math.max( 
          element.qteFaceRetourStockCoupe,
          element.qteFaceRetourStockBroderie,
          element.qteDosRetourStockCoupe,
          element.qteDosRetourStockBroderie, 
          element.qteManchesRetourStockCoupe / 2 
        );

        console.log( element.qteFaceValide, element.qteDosValide , element.qteManchesValide/2 );

        console.log(Math.min(element.qteFaceValide,element.qteDosValide ,element.qteManchesValide/2));
        element.qteQuarantaine = element.nombreObjectif - Math.min(element.qteFaceValide,element.qteDosValide ,element.qteManchesValide/2)

        element.typeCoupe = tCoup.filter((e) => e?.id === element?.prodTypeCoupeId)[0]?.nom ?? ""
        element.typeTissus = tTissus.filter((e) => e?.id === element?.prodTypeTissuId )[0]?.nom ?? ""
        element.createdAt = moment(element.createdAt).format("YYYY-MM-DD")
      }
      var temp = sortByCreatedAt(data)
      setDataTable(temp)
    };

    const sortByCreatedAt = (data) => {
      return data.sort((a,b)=>{
           return new Date(a.createdAt).getTime() - 
           new Date(b.createdAt).getTime()
       }).reverse();
   }
    
    const showAssemblageModal = () => {
      setUpdateType("Ajout d'un ordre d'assemblage avec success")
      setAssemblageModal(true);
    };
  
    const handleOk = () => {
      setAssemblageModal(false);
    };
  
    const updateMode = (record) => {
      setAssemblageModal(true);
      assemblageForm.setFieldsValue(record);
      setUpdateElement(record);
      setUpdateType("Mise à jours de ordre d'assemblage avec success");
      setUpdateId(record.id);
      setEditMode(true);

      setTimeout( () =>{
        showCommandeField();
        loadStockQte();
      }, 100)
    };
    
    const showMode = (record) => {
      setUpdateId(record.id);
      setEditMode(true);
      assemblageForm.setFieldsValue(record);
    }

    const loadStockQte = async () => {
      setLoading(true);
      const config = assemblageForm.getFieldsValue();
      if( config.prodTypeCoupeId !== undefined && config.prodTypeTissuId !== undefined ){
        await loadStockCoupeQte(config);
        await loadStockBroderieQte(config);
        setMaxQte();
      }
      setLoading(false);
    }

    const setMaxQte = () => {
      const data = assemblageForm.getFieldsValue();
      const faces = data.qteFaceStockCoupe + data.qteFaceStockBroderie;
      const dos = data.qteDosStockCoupe + data.qteDosStockBroderie; 
      const manches = data.qteMancheStockCoupe / 2;
      const max = Math.max( faces, dos, manches)
      setObjectifMax( max )

    }

    const loadStockCoupeQte = async ( config ) => {
      const response = await OrdreCoupeService.loadStockQte({prodTypeCoupeId:config.prodTypeCoupeId, prodTypeTissuId: config.prodTypeTissuId});
      const qteFaceStockCoupe = response?.data?.face ?? 0;
      const qteDosStockCoupe = response?.data?.dos ?? 0;
      const qteMancheStockCoupe = response?.data?.manches ?? 0;
      assemblageForm.setFieldsValue({qteFaceStockCoupe, qteDosStockCoupe, qteMancheStockCoupe})
    }

    const loadStockBroderieQte = async ( config ) => {
      const response = await BroderieService.loadStockQte({prodTypeCoupeId:config.prodTypeCoupeId, prodTypeTissuId: config.prodTypeTissuId, produitId:config.produitId });
      const qteFaceStockBroderie = response?.data?.face ?? 0;
      const qteDosStockBroderie = response?.data?.dos ?? 0;
      assemblageForm.setFieldsValue({qteFaceStockBroderie, qteDosStockBroderie})
    }

    const showCommandeField = () => {
      const config = assemblageForm.getFieldsValue();
      setSelectedType(config.type);
    }
  

    const openControleQualiteModal = ( ordreAssemblage ) => {
      console.log(ordreAssemblage);

      ordreAssemblage.qteFaceTotal = ordreAssemblage.qteFaceRemoveStockBroderie + ordreAssemblage.qteFaceRemoveStockCoupe;
      ordreAssemblage.qteDosTotal = ordreAssemblage.qteDosRemoveStockBroderie + ordreAssemblage.qteDosRemoveStockCoupe;
      assemblageForm.setFieldsValue(ordreAssemblage);
      assemblageForm.setFieldsValue({
        oldDosValide: ordreAssemblage.qteDosValide,
        oldFaceValide: ordreAssemblage.qteFaceValide,
        oldManchesValide: ordreAssemblage.qteManchesValide,
      })
      
      setUpdateElement(ordreAssemblage);
      setUpdateType("Mise à jours du controle qualité");
      setUpdateId(ordreAssemblage.id);
      setEditMode(true);
      setControleQualiteModal(true);
    }

    const openProductionModal = ( ordreAssemblage ) => {
      console.log(ordreAssemblage);

      const totalProduit = ordreAssemblage.totalProduit;
      const oldTotal = ordreAssemblage.totalProduit;

      assemblageForm.setFieldsValue({totalProduit , oldTotal});
      setUpdateElement(ordreAssemblage);
      setUpdateType("Mise à jours de production");
      setUpdateId(ordreAssemblage.id);
      setEditMode(true);
      setProductionModal(true);
    }

    const addAssemblage = async ( values ) => {
      setLoading(true)
      values.etat = 0
      if( values.qteDosValide >= 1 && values.qteFaceValide >= 1 && values.qteManchesValide >= 0 ) values.etat = 2;
      if( Number(values.totalProduit) >= 1) values.etat = 3;
      if( Number(values.totalProduit) >= updateElement?.nombreObjectif ) values.etat = 1;
      setLoading(true);
      const response = editMode
        ? await AssemblageService.updateAssemblage(updateId, values)
        : await AssemblageService.addAssemblage(values);

      if (response.status === 200 || 204) {
        message.info( updateType );
      } else message.error("Une erreur est survenu ! ");

      await closingModal();
    };

    const objectifChanged = () => {
      setLoading(true);
      const config = assemblageForm.getFieldsValue();
      let qteFaceRemoveStockCoupe = 0
      let qteDosRemoveStockCoupe = 0
      let qteFaceRemoveStockBroderie = config.nombreObjectif;
      let qteDosRemoveStockBroderie = config.nombreObjectif;
      
      const qteManchesRemoveStockCoupe = config.nombreObjectif * 2;

      if(config.qteFaceStockBroderie == 0){
        qteFaceRemoveStockBroderie = 0
        qteFaceRemoveStockCoupe = config.nombreObjectif
      }

      if(config.qteDosStockBroderie == 0){
        qteDosRemoveStockBroderie = 0
        qteDosRemoveStockCoupe = config.nombreObjectif
      }

      assemblageForm.setFieldsValue({
        qteFaceRemoveStockCoupe,
        qteFaceRemoveStockBroderie,
        qteDosRemoveStockCoupe,
        qteDosRemoveStockBroderie,
        qteManchesRemoveStockCoupe,
      });

      setLoading(false)
    }

    const qteFaceStockCoupeChanged = () => {
      setLoading(true);
      assemblageForm.setFieldsValue({ qteFaceRemoveStockBroderie: 0 });
      setLoading(false)

    }

    const qteFaceStockBroderieChanged = () => {
      setLoading(true);
      assemblageForm.setFieldsValue({ qteFaceRemoveStockCoupe: 0 });
      setLoading(false)

    }

    const qteDosStockCoupeChanged = () => {
      setLoading(true);
      assemblageForm.setFieldsValue({ qteDosRemoveStockBroderie: 0 });
      setLoading(false)

    }

    const qteDosStockBroderieChanged = () => {
      setLoading(true);
      assemblageForm.setFieldsValue({ qteDosRemoveStockCoupe: 0 });
      setLoading(false)
    }

    const closingModal = async () => {
      setAssemblageModal(false);
      setControleQualiteModal(false);
      setProductionModal(false);
      setStateChange(false);
      clearData();
      await loadAssemblages();
    };
  
    const clearData = () => {
      assemblageForm.resetFields();
      assemblageForm.setFieldsValue({ type: false });
      setEditMode(false);
      setUpdateId(null);
      setSelectedType(false);
      setLoading(false) 
    };

    const locale = {
      emptyText: (
        <Empty description={"Le tableau est vide pour le moment "}></Empty>
      ),
    };

    const calculeTotalProduit = () => {
      setLoading(true);
      const config = assemblageForm.getFieldsValue();
      console.log(config);
      const totalProduit = config.oldTotal + config.nouveauProduit;
      assemblageForm.setFieldsValue({ totalProduit: totalProduit });
      setLoading(false);

    }

    const colums = [
      {
        title: "Num",
        dataIndex: "id",
        sorter: (a, b) => a.id - b.id,
      },
      {
        title: "Type",
        dataIndex: "showType",
        sorter: (a, b) => a.showType.localeCompare(b.showType),
      },
      {
        title: "Type Coupe",
        dataIndex: "typeCoupe",
        sorter: (a, b) => a.showType.localeCompare(b.showType),
      },
      {
        title: "Type Tissus",
        dataIndex: "typeTissus",
        sorter: (a, b) => a.showType.localeCompare(b.showType),
      },
      {
        title: "Produit",
        dataIndex: "showProduit",
        sorter: (a, b) => a.showProduit.localeCompare(b.showProduit),
      },
      {
        title: "Bon commande",
        dataIndex: "bonCommande",
        sorter: (a, b) => a.bonCommande.localeCompare(b.bonCommande),
      },
      {
        title: "Qte. objectif",
        dataIndex: "nombreObjectif",
        sorter: (a, b) => a - b
      },{
        title: "Qte. Attente de Correction",
        dataIndex: "qteCorrection",
        sorter: (a, b) => a - b
      },
      {
        title: "Qte. en Quarantaine",
        dataIndex: "qteQuarantaine",
        sorter: (a, b) => a - b
      },
      {
        title: "Total Produit",
        dataIndex: "totalProduit",
        sorter: (a, b) => a - b
      },
      {
        title: "Date de Creation",
        dataIndex: "createdAt",
        sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      },
      ,{
        title: "Etat",
        dataIndex: "etat",
        width: "15%",
        render: (text, record) => (
          <>
            {record.etat === 1 && 
              <Tag color="#2db7f5">Validé</Tag>
            }
            {record.etat === 0 && 
              <Tag color="#87d068">QC En cours</Tag>
            }
            {record.etat === 2 && 
              <Tag color="#87d068">Assemblage en cours</Tag>
            }
            {record.etat === 3 && 
              <Tag color="#87d068">Partiellement Achevée</Tag>
            }
            {record.etat === -1 && 
              <Tag color="#f50">Annulé</Tag>
            }
          </>
        ),
        sorter: (a, b) => a.etat - b.etat,
      },
      {
        title: "Actions",
        key: "action",
        width: "15%",
        render: (text, record) => (
          <div>

            { record.etat === 0 && 
                <Tooltip title="Mettre à jour">
                  <Button
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    disabled={!Can("9_3")}
                    onClick={() => updateMode(record)}
                    icon={<EditOutlined />}
                    />
                </Tooltip>
            }

            { (record.etat === 0 || record.etat === 2 || record.etat === 3) &&
              <Tooltip title="Controle Qualité">
                <Button
                  className="mx-1"
                  type="dashed"
                  shape="circle"
                  disabled={!Can("9_3")}
                  onClick={() => openControleQualiteModal(record)}
                  icon={<HighlightOutlined />}
                />
              </Tooltip>
            }

            { (record.etat === 2 || record.etat === 3) &&
              <>
                <Tooltip title="Production">
                  <Button
                    className="mx-1"
                    type="dashed"
                    shape="circle"
                    onClick={() => openProductionModal(record)}
                    icon={<ToolOutlined />}
                  />
                </Tooltip>
              </>
            }
          </div>
        ),
      },
    ];

    const subColums = [
      {
        title: "Composante",
        dataIndex: "typeComposante",
      },{
        title: "Quantité Totale",
        dataIndex: "qteTotale",
      },{
        title: "Quantité Validée",
        dataIndex: "qteValide",
      },{
        title: "Quantité Retour Coupe",
        dataIndex: "qteRetourCoupe",
      },{
        title: "Quantité Retour Broderie",
        dataIndex: "qteRetourBroderie",
      }
    ];

    const produitChanged = () => {
      const config = assemblageForm.getFieldsValue();
      if (config.produitId){
        const produit = produits.find( (e) => e.id === Number(config.produitId) );
        assemblageForm.setFieldsValue({
          prodTypeCoupeId: produit.prodTypeCoupeId,
          prodTypeTissuId: produit.prodTypeTissuId,
          prodReferenceBroderieId: produit.prodReferenceBroderieId,
        })
        loadStockQte()
      }
    }


    const newQteFaceChanged = () => {
      const config = assemblageForm.getFieldsValue();
      console.log(config.oldFaceValide);
      console.log(config)

      assemblageForm.setFieldsValue({
        qteFaceValide: config.oldFaceValide + config.newQteFaceValide,
      })
    }

    const newQteDosChanged = () => {
      const config = assemblageForm.getFieldsValue();
      assemblageForm.setFieldsValue({ 
        qteDosValide: config.oldDosValide + config.newQteDosValide
      })


    }
  
    
    const newQteManchesChanged = () => {
      const config = assemblageForm.getFieldsValue();
      assemblageForm.setFieldsValue({
        qteManchesValide: config.oldManchesValide + config.newQteManchesValide,
      })
    }
  
    return (
      <>
        <Spin spinning={isLoading} size="large">
          <Card
              title={"Assemblage"}
              extra={ 
              <>
                <Button className="mx-2" shape="round" type="primary" onClick={() => showAssemblageModal()} >
                    Ajouter un ordre d'assemblage
                </Button>
              </>
              }
          >
            <Table columns={colums}
              dataSource={dataTable}
              pagination={{ position: ["bottomCenter"], pageSize: 50 }}
              scroll={{ y: 500 }}
              rowKey={(record) => record.id}
              expandable={{
                rowExpandable: record => record.id !== null,
                expandedRowRender: record => <>
                    <Table
                      rowKey={record.id}
                      locale={locale}
                      columns={subColums}
                      dataSource={record.composantes}
                      pagination={{ position: ["none"], pageSize: 50 }}          
                    />
                </>,
              }}
            />


            <Form layout="vertical" form={assemblageForm} onFinish={addAssemblage} disabled={isLoading} initialValues={{type:3}} >
              <Modal
                  destroyOnClose={true}
                  visible={assemblageModal}
                  onOk={assemblageForm.submit}
                  onCancel={closingModal}
                  getContainer={false}
                  confirmLoading={isLoading}
                  okText="Enregistrer"
                  cancelText="Annuler"
                  centered
                  width={1200}
                  title={
                  editMode
                      ? "Modifier l'ordre de assemblage"
                      : "Ajouter Un ordre de assemblage"
                  }
              >


                <Tabs defaultActiveKey="1">
                  <Tabs.TabPane tab="Informations Générales" key="1">
                    <Row>
                      <Col span={8} className="px-2">
                        <Form.Item label="Type d'ordre d'assemblage" name="type"
                          rules={[
                              {
                              required: true,
                              message:
                                  "Champ obligatoire avec maximum de caractère 100 !",
                              },
                          ]}
                        >
                          <Select
                          size="large" 
                          onChange={() => showCommandeField()}
                          dropdownStyle = {{ position: "fixed" }}
                          >
                              {typeBroderie &&
                                  typeBroderie.map((c) => (
                                      <Select.Option key={c.id} value={c.id} >
                                      {c.type}  
                                      </Select.Option>
                                  ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8} className="px-2" >
                        <Form.Item 
                          label="Produit" name="produitId"
                          rules={[ { required: true, message: "Champ obligatoire !" }]}
                        >
                            {/* <Select 
                            size="large" 
                            onChange={() => produitChanged()}
                            dropdownStyle = {{ position: "fixed" }}
                            >
                              {produits &&
                                produits.map((c) => (
                                    <Select.Option key={c.id} value={c.id} >
                                    {c.libelle}  
                                    </Select.Option>
                                ))}
                            </Select> */}
                             <Select
                              size="large" 
                              allowClear
                              showSearch
                              dropdownStyle = {{ position: "fixed" }}
                              onChange={() => produitChanged()}
                              filterOption={(inputValue, option) =>
                              option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 
                              || option.props.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                              }
                              >
                                {produits.map(option=> (
                                  <Select.Option key={option.id}>{option.libelle}</Select.Option>
                              ))}
                          </Select>                          
                        </Form.Item>
                      </Col>
                      {
                        selectedType === 1 &&
                        <Col span={8} className="px-2" >
                            <Form.Item 
                              label="Client" name="clientId"
                              rules={[ { required: true, message: "Champ obligatoire !" }]}
                            >
                                <Select size="large">
                                    {clients &&
                                        clients.map((c) => (
                                            <Select.Option key={c.id} value={c.id} >
                                            {c.nom} {c.prenom}  
                                            </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                      }
                      {
                        selectedType === 2 &&
                        <Col span={8} className="px-2" >
                            <Form.Item 
                              label="Fournisseur" name="fournisseurId"
                              rules={[ { required: true, message: "Champ obligatoire !" }]}
                            >
                                <Select size="large">
                                    {fournisseurs &&
                                        fournisseurs.map((c) => (
                                          <Select.Option key={c.id} value={c.id} >
                                            {c.nom} {c.prenom}  
                                          </Select.Option>
                                        ))}
                                </Select>
                            </Form.Item>
                        </Col>
                      }
                    </Row>

                    <Row>
                      <Col span={6} className="px-2">
                          <Form.Item
                              label="Type de Coupe"
                              name="prodTypeCoupeId"
                              rules={[{
                                  required: true,
                                  message: "Champ obligatoire  !",
                              },
                          ]}
                          >
                              <Select size="large" disabled>
                                  {typeCoupe &&
                                      typeCoupe.map((c) => (
                                          <Select.Option key={c.id} value={c.id}>
                                          {c.nom}  
                                          </Select.Option>
                                      ))}
                              </Select>
                          </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Type de Tissus"
                          name="prodTypeTissuId"
                          rules={[
                              {required :true , message : "Champ obligatoire !"},
                          ]}
                          >
                            <Select size="large" disabled>
                                {typeTissu &&
                                    typeTissu.map((c) => (
                                        <Select.Option
                                            key={c.id}
                                            value={c.id}
                                        >
                                        {c.nom}  
                                        </Select.Option>
                                    ))}
                            </Select>
                          </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                            label="Référence Broderie"
                            name="prodReferenceBroderieId"
                        >
                          <Select size="large"  disabled={true}>
                            {referencesBroderies.map((m) => (
                              <Select.Option key={m.id} value={m.id}>
                                {m.nom}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={6} className="px-2">
                        <Form.Item
                          label="Nombre de Pièces Objectif"
                          name="nombreObjectif"
                          rules={[ {required :true , message : "Champ obligatoire !"} ]}
                        >
                          <InputNumber size="large" style={{ width: "100%" }} max={objectifMax}  onChange={() => objectifChanged()} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Tabs.TabPane>
                  <Tabs.TabPane forceRender={true} tab="Stock" key="2">
                      <Row className="pt-2 pb-3">
                        <Col span={12} className="px-5"><Divider orientation="left">Stock Coupe</Divider> </Col>
                        <Col span={12} className="px-5"><Divider orientation="left">Stock Broderie</Divider> </Col>
                      </Row>
                      <Row>
                        <Col span={6} className="px-2">
                          <Form.Item
                              label="Quantite Face Stock Coupe" name="qteFaceStockCoupe"
                              rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} disabled  />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2">
                          <Form.Item
                            label="Quantite à retirer du stock" name="qteFaceRemoveStockCoupe"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >

                            <InputNumber size="large" style={{ width: "100%" }} min={0} 
                              max={assemblageForm.getFieldValue("qteFaceStockCoupe")}
                              onChange={() => qteFaceStockCoupeChanged()}  />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2" >
                          <Form.Item 
                            label="Quantite Face Stock Broderie" name="qteFaceStockBroderie"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} min={0} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2" >
                          <Form.Item 
                            label="Quantite à retirer du stock" name="qteFaceRemoveStockBroderie"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} min={0} 
                              max={assemblageForm.getFieldValue("qteFaceStockBroderie")}
                              onChange={() => qteFaceStockBroderieChanged()}  />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6} className="px-2">
                          <Form.Item
                            label="Quantite Dos Stock Coupe" name="qteDosStockCoupe"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} min={0} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2">
                          <Form.Item
                            label="Quantite à retirer du stock" name="qteDosRemoveStockCoupe"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} min={0} 
                              max={assemblageForm.getFieldValue("qteDosStockCoupe")}
                              onChange={() => qteDosStockCoupeChanged()} />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2" >
                          <Form.Item 
                            label="Quantite Dos Stock Broderie" name="qteDosStockBroderie"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} min={0} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2" >
                          <Form.Item 
                            label="Quantite à retirer du stock" name="qteDosRemoveStockBroderie"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} min={0} 
                              max={assemblageForm.getFieldValue("qteDosStockBroderie")}
                              onChange={() => qteDosStockBroderieChanged()} />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={6} className="px-2">
                          <Form.Item
                            label="Quantite Manches Stock Coupe" name="qteMancheStockCoupe"
                            rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} disabled />
                          </Form.Item>
                        </Col>
                        <Col span={6} className="px-2" >
                          <Form.Item 
                              label="Quantite à retirer du stock" name="qteManchesRemoveStockCoupe"
                              rules={[ { required: true, message: "Champ obligatoire !" }]}
                          >
                            <InputNumber size="large" style={{ width: "100%" }} max={assemblageForm.getFieldValue("qteMancheStockCoupe")}/>
                          </Form.Item>
                        </Col>
                      </Row>
                  </Tabs.TabPane>
                </Tabs> 
              </Modal>
            </Form>


            <Form layout="vertical" form={assemblageForm} onFinish={addAssemblage} disabled={isLoading} >
              <Modal
                  destroyOnClose={true}
                  visible={controleQualiteModal}
                  onOk={assemblageForm.submit}
                  onCancel={closingModal}
                  getContainer={false}
                  confirmLoading={isLoading}
                  okText="Enregistrer"
                  cancelText="Annuler"
                  centered
                  width={1200}
                  title={
                  editMode
                    /*   ? "Modifier l'ordre de assemblage" */
                      ? "Controle Qualité"
                      : "Ajouter Un ordre de assemblage"
                  }
              >

                <Row>
                  <Col span={24}>
                    <Divider orientation="left"> Faces </Divider>
                  </Col>
                    <Col span={4} className="px-2">
                        <Form.Item name="qteFaceTotal" label="Qte de l'operation" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <Input size="large" type="text" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="qteFaceValide" label="Qte Validé et sortie du stock" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber disabled size="large" style={{ width: "100%" }} min={0}  />
                        </Form.Item>
                        <Form.Item name="oldFaceValide" style={{visibility: "hidden", width:"0px", height:"0px",margin:"0px",padding:"0px" }} >
                          <InputNumber size="large" style={{ width: "100%" }} min={0}  />
                        </Form.Item>
                    </Col>

                    <Col span={5} className="px-2">
                        <Form.Item name="newQteFaceValide" label="Nouvelle Qte Validé" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large" style={{ width: "100%" }} min={0} onChange={() => newQteFaceChanged()} />
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="qteFaceRetourStockCoupe" label="Qte retour stock coupe" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large" style={{ width: "100%" }} min={0} max={
                            assemblageForm.getFieldValue("qteFaceTotal") - 
                            assemblageForm.getFieldValue("qteFaceValide") - 
                            assemblageForm.getFieldValue("qteFaceRetourStockBroderie") 
                          }/>
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="qteFaceRetourStockBroderie" label="Qte retour stock broderie" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large" style={{ width: "100%" }} min={0} max={
                            assemblageForm.getFieldValue("qteFaceTotal") - 
                            assemblageForm.getFieldValue("qteFaceValide") - 
                            assemblageForm.getFieldValue("qteFaceRetourStockCoupe") 
                          }/>
                        </Form.Item>
                    </Col>
                </Row>

                <Row>
                    <Col span={24}>
                      <Divider orientation="left"> Dos </Divider>
                    </Col>

                    <Col span={4} className="px-2">
                        <Form.Item name="qteDosTotal" label="Qte de l'operation"
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <Input size="large" type="text" disabled />
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="qteDosValide" label="Qte sortie du stock et Validé" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large" disabled style={{ width: "100%" }} min={0} />
                        </Form.Item>
                        <Form.Item name="oldDosValide" style={{ visibility: "hidden", width:"0px", height:"0px",margin:"0px",padding:"0px" }}  >
                          <Input  size="large" style={{ width: "100%" }} min={0}  />
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="newQteDosValide" label="Nouvelle Qte Validé" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large"  style={{ width: "100%" }} min={0} onChange={() => newQteDosChanged()} />
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="qteDosRetourStockCoupe" label="Qte retour stock coupe" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large" style={{ width: "100%" }} min={0} max={
                            assemblageForm.getFieldValue("qteDosTotal") - 
                            assemblageForm.getFieldValue("qteDosValide") - 
                            assemblageForm.getFieldValue("qteDosRetourStockBroderie") 
                          }/>
                        </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="qteDosRetourStockBroderie" label="Qte retour stock broderie" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large" style={{ width: "100%" }} min={0} max={
                            assemblageForm.getFieldValue("qteDosTotal") - 
                            assemblageForm.getFieldValue("qteDosValide") - 
                            assemblageForm.getFieldValue("qteDosRetourStockCoupe") 
                          }/>
                        </Form.Item>
                    </Col>
                </Row>
                
                <Row>
                    <Col span={24}>
                      <Divider  orientation="left"> Manches </Divider>
                    </Col>
                    <Col span={4} className="px-2">
                      <Form.Item name="qteManchesRemoveStockCoupe" label="Qte de l'operation" 
                        rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                      >
                        <Input size="large" type="text" disabled  />
                      </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                      <Form.Item name="qteManchesValide" label="Qte sortie du stock et Validé" 
                        rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                      >
                        <InputNumber disabled size="large" style={{ width: "100%" }} min={0} />
                      </Form.Item>
                      <Form.Item name="oldManchesValide" style={{visibility: "hidden", width:"0px", height:"0px",margin:"0px",padding:"0px" }}  >
                        <InputNumber size="large" style={{ width: "100%" }} min={0}  />
                      </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                      <Form.Item name="newQteManchesValide" label="Nouvelle Qte Validé" 
                        rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                      >
                        <InputNumber size="large" style={{ width: "100%" }} min={0} onChange={() => newQteManchesChanged()} />
                      </Form.Item>
                    </Col>
                    <Col span={5} className="px-2">
                        <Form.Item name="qteManchesRetourStockCoupe" label="Qte retour stock coupe" 
                          rules={[{ required: true, message: "Champ obligatoire avec maximum de caractère 100 !", }]}
                        >
                          <InputNumber size="large" style={{ width: "100%" }} min={0} max={
                            assemblageForm.getFieldValue("qteManchesRemoveStockCoupe") - 
                            assemblageForm.getFieldValue("qteManchesValide")
                          }/>
                        </Form.Item>
                    </Col>
                </Row>
              </Modal>
            </Form>


            <Form layout="vertical" form={assemblageForm} onFinish={addAssemblage} disabled={isLoading} >
              <Modal
                destroyOnClose={true}
                visible={productionModal}
                onOk={assemblageForm.submit}
                onCancel={closingModal}
                getContainer={false}
                confirmLoading={isLoading}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={700}
                title="Production"
              >
                <Row>
                  <Col span={12} className="px-2">
                    <Form.Item name="totalProduit" label="Quantité Totale Assemblée" 
                      rules={[{ required: true, message: "Champ obligatoire !" }]}
                    >
                      <InputNumber disabled size="large" min={0} style={{ width: "100%" }} max={updateElement?.nombreObjectif -  updateElement?.qteQuarantaine } />
                    </Form.Item>
                    <Form.Item name="oldTotal" style={{visibility: "hidden", width:"0px", height:"0px",margin:"0px",padding:"0px" }} >
                      <InputNumber size="large" style={{ width: "100%" }} min={0}  />
                    </Form.Item>
                  </Col>
                  <Col span={12} className="px-2">
                    <Form.Item name="nouveauProduit" label="Nouvelle Quantité Assemblée " 
                      rules={[{ required: true, message: "Champ obligatoire !" }]}
                    >
                      <InputNumber size="large" min={0} style={{ width: "100%" }} max={updateElement?.nombreObjectif -  updateElement?.totalProduit } onChange={() => calculeTotalProduit()} />
                    </Form.Item>
                  </Col>
                </Row>
              </Modal>
            </Form>
          </Card>
        </Spin>
      </>
    );
  };
  export default Assemblage;
  