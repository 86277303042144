import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addFournisseur = ( fournisseur ) => {
  return axios.post(API_URL + "/fournisseur/" , fournisseur ,{ headers: authHeader() });
};

const getFournisseurs = async () => {
  return await axios.get(API_URL + "/fournisseur/" , { headers: authHeader() });
};

const getFournisseur = (id) => {
  return axios.get(API_URL + "/fournisseur/" + id, { headers: authHeader() });
};

const getFournisseurNames = async ( callback ) => {
    callback( await axios.get(API_URL + "/fournisseur/names", { headers: authHeader() }));
};

const updateFournisseur = (id , fournisseur ) => {
  return axios.put(API_URL + "/fournisseur/" + id , fournisseur, { headers: authHeader() });
};

const deleteFournisseur = (id) => {
  return axios.delete(API_URL + "/fournisseur/" + id , { headers: authHeader() });
};
const findFournisseurStats = (id) => {
  return axios.get(API_URL + "/fournisseur/findFournisseurStats/" + id, { headers: authHeader() });
} 

const exportsMethodes = {
  addFournisseur,
  getFournisseurs,
  updateFournisseur,
  deleteFournisseur,
  getFournisseur,
  getFournisseurNames,
  findFournisseurStats
};
export default exportsMethodes;