import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addEntreprise = ( entreprise ) => {
  return axios.post(API_URL + "/auth/" , entreprise ,{ headers: authHeader() });
};

const loginEntreprise = ( entreprise ) => {
  return axios.post(API_URL + "/auth/login" , entreprise ,{ headers: authHeader() });
};

const getEntreprise = ( ) => {
  return axios.get(API_URL + "/entreprise/" ,{ headers: authHeader() });
};

const updateEntreprise = (id , entreprise ) => {
  return axios.put(API_URL + "/entreprise/" + id , entreprise, { headers: authHeader() });
};

const exportsMethodes = {
  addEntreprise,
  loginEntreprise,
  getEntreprise,
  updateEntreprise,
};
export default exportsMethodes;