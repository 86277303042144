import {
  CheckOutlined, DeleteOutlined, PlusCircleOutlined, MinusCircleOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col, Divider, Form, Input, InputNumber, message, Modal, Row,
  Spin,
  Tooltip,
  Select
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Can from "../../../security/Can";
import BonLivraisonService from "../../../services/bonLivraison.service";
import ClientService from "../../../services/client.service";
import EntrepriseService from "../../../services/entreprise.service";
import ProduitService from "../../../services/produit.service";
import TaxeService from "../../../services/taxe.service";
import ConfectionService from "../../../services/confection.service";
import AppTable from "../../uiHelpers/AppTabe";
import FicheClient from "../../uiHelpers/Fiches/FicheClient";
import FicheDetails from "../../uiHelpers/Fiches/FicheDetails";
import FicheFooter from "../../uiHelpers/Fiches/FicheFooter";
import FicheFournisseur from "../../uiHelpers/Fiches/FicheFournisseur";
import FicheHeader from "../../uiHelpers/Fiches/FicheHeader";
import FicheTableGain from "../../uiHelpers/Fiches/FicheTableGain";
import FicheTableTotaux from "../../uiHelpers/Fiches/FicheTableTotaux";
import FicheTableTva from "../../uiHelpers/Fiches/FicheTableTva";
import NotAuthorized from "../../uiHelpers/NotAuthorized";
import AddProductModalForm from "../General/AddProductModalForm";
import writtenNumber from '/node_modules/written-number/lib/index';
import currencys from '../../../helpers/currency';
const { Search } = Input;

writtenNumber.defaults.lang = 'fr';

function BonLivraisonEnLigne() {
  const [productsForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [fournisseurs, setFournisseurs] = useState([]);
  const [fournisseur, setFournisseur] = useState(null);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState(null);
  const [entreprise, setEntreprise] = useState({});
  const [visualisationModal, setVisualisationModal] = useState(false);
  const [productsModal, setProductsModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isDarkMode, setDarkMode] = useState(false);
  const [productList, setProductList] = useState([]);
  const [selectingProductList, setSelectingProductList] = useState([]);
  const [productSearch, setProductSearch] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const [appliedTaxes, setListAppliedTaxes] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [gains, setGain] = useState([]);
  const [fodec , setFodec] = useState(false);
  const [productModal , setProductModal] = useState(false);
  const [writtenTotal,setWrittenTotal] = useState("");
  const [selectedCurrency,setSelectedCurrency] = useState("");
  const [oldMontantTotal,setoldMontantTotal] = useState("");
  const [blToUpdate,setBlToUpdate] = useState({});



  const history = useHistory();

  const location = useLocation();

  const [prixTotal, setPrixTotal] = useState({
    montant_total: 0,
    total_ttc: 0,
    total_ht: 0,
    fodec: 0,
    tva: 0,
    totalTimbreFiscal: "0.000",
  });

  const isVente = true;

  useEffect( async () => {

    await loadProduits();
    await loadClients();
    await loadEntreprise();
    await getThemeMode();
    await setUpdate();
    return () => {
      clearStates();
    };
  }, []);

  const loadTaxeFodec = async () => {
    const response = await TaxeService.checkTaxeFodec();
    setFodec(response.data);
  }

  const setUpdate = async () => {
    if( location.state?.id ){
      const id = location.state?.id;
      setLoading(true); 
      const response = await BonLivraisonService.getBonLivraison(id)
        if (response.status === 200 || 204) {
          setBlToUpdate(response.data)
          const activeFodec = response.data.fodec > 0 ? true : false; 
          setFodec(activeFodec);
          const clientId = response.data.produit_bon_livraisons[0].clientId;
          const productList = {...response.data }
          productList.date = moment(productList.date);
          productList.client = clientId;
          productsForm.setFieldsValue(productList);
          loadProduits();
          calculeTotalTaxes(activeFodec);
          const ids = []
          for (const element of productList.produit_bon_livraisons) ids.push(element.produitId);
          setSelectedIds(ids);
          setUpdateId(id);
          // clientChanged();
          
          productsForm.setFieldsValue({client: response.data?.clientId})
          const clientList = clients.length ? clients : await getClients();
          const cli =  clientList.find((client) => client.id === response.data?.clientId);
          setClient( cli );

          setSelectedCurrency(response.data.devise);
          setEditMode(true);
        }else message.error("Une erreur est survenu ! "); 
        setLoading(false);
    }else{
      await loadTaxeFodec()
      generateReference();
    }
  }
  const generateReference = async () => {
    await BonLivraisonService.getLast( ( response )=> {
      if (response.status === 200 || 204) {
        if(response.data.length === 0){
          var nbr  = "1";
          var reference = "BL-" + nbr.padStart(4, '0');
        }else{
          const lastRef = response.data.reference;
          const refNbr = (Number(lastRef.substring(3, 7)) + 1 ) + "";
          var reference = "BL-" + refNbr.padStart(4, '0');
        }
        productsForm.setFieldsValue({reference: reference ,date : moment(new Date()) } )
      }else message.error("Une erreur est survenu ! ");
      setLoading(false);
    });
  }

  const loadProduits = async () => {
    setLoading(true);
    // const response = await ProduitService.getProduitFini();
    const response = await ProduitService.getProduits()
    if (response.status === 200 || 204) initTableData(response.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const getProducts = async () => {
    const response = await ProduitService.getProduitFini();
    if (response.status === 200 || 204) return response.data;
    else message.error("Une erreur est survenu ! ");
  };
  
  const initTableData = async (produits) => {
    console.log(produits)
    const produitsService = produits.filter((it)=> [1].includes(it.type))
    const produitsFinit = produits.filter((it)=> [2].includes(it.type))
    const temp = await getProductsOfStockConfection(produitsFinit)
    produits = produitsService.concat(temp)
    produits = formatteProductsToDataTable(produits);
    setProductList(produits);
    setProductSearch(produits)
    setSelectingProductList(produits)
  };
  const reloadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const formatteProductsToDataTable = (produits) => {
    for (const produit of produits) {
      produit.showType = produit.type ? "Service" : "Produit";
      produit.ttcAchat = produit.produit_achats[0]?.prixTTC;
      produit.htAchat = produit.produit_achats[0]?.prixHT;
      produit.pqte =  !produit.type ? produit.stocks[0]?.quantite : "";
      produit.fournisseurId = produit.produit_achats[0]?.fournisseurId;
      produit.showFournisseur =produit.produit_achats[0]?.fournisseur?.raison_sociale ?? "Produit proprietere";
      produit.ttcVente = produit.produit_vente_en_lignes[0]?.prixTTC;
      produit.htVente = produit.produit_vente_en_lignes[0]?.prixHT;
      produit.qte =  produit?.stocks[0]?.quantite
    }
    return produits;
  };

  const getProductsOfStockConfection = async (produits) => {
    let stockConfection = [] 
    const response = await ConfectionService.loadStock()
    if (response.status === 200 || 204){
      stockConfection =  response.data;
      stockConfection = stockConfection.map(it=> it.produitId)
      produits = produits.filter((it)=> stockConfection.includes(it.id))
      return produits
    } 
    else message.error("Une erreur est survenu ! ");
  }

  const selectProduct = (produit) => {
    if (produit == null) return
    setSelectedIds((prev) => [...prev, produit.id]);
    formatteProduct(produit);
    filterSelectedProduitFromSelectedIds(produit)
  };

  const addProductOnSelect = () => {
    const config = productsForm.getFieldsValue();
    const produit = productList.find( (e) => e.id === Number(config.produitToSelect) );
    if ( selectedIds.filter((it)=> it == produit?.id).length > 0 ) return
    selectProduct(produit)
    productsForm.setFieldsValue({produitToSelect:null})
  }

  const filterSelectedProduitFromSelectedIds = (produit) => {
    let products = [...selectingProductList]
    let temp = products.filter((it)=> it.id !== produit.id )
    setSelectingProductList(temp)
  }

  const removeElement = (produitId) => {
    const list = selectedIds;
    setSelectedIds(list.filter((idP) => idP !== produitId));
    const prod = productList.find((it)=> it.id == produitId )
    setSelectingProductList((prev) => [...prev,prod])
  };

  const isSelected = (id) => {
    return selectedIds.includes(id);
  };

  const isProduitWithVenteEnLigne = (record) => {
    return record.produit_vente_en_lignes?.length > 0 
  }

  const formatteProduct = (produit) => {
    const qte = produit?.quantite ?? 1;

    if( isVente ){
      var prixUniteHt = produit?.produit_vente_en_lignes[0]?.prixHT;
      var prixUniteTTC = produit?.produit_vente_en_lignes[0]?.prixTTC;
      var tva = produit?.produit_vente_en_lignes[0]?.tva
      var ht =  produit?.produit_vente_en_lignes[0]?.total_prix_HT ?? Number(prixUniteHt) * Number(qte);
      var ttc = produit?.produit_vente_en_lignes[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);

    }else{
      var prixUniteHt = produit?.produit_achats[0]?.prixHT;
      var prixUniteTTC = produit?.produit_achats[0]?.prixTTC;
      var tva = produit?.produit_achats[0]?.tva;
      var ht = produit?.produit_achats[0]?.total_prix_HT  ?? Number(prixUniteHt) * Number(qte);
      var ttc = produit?.produit_achats[0]?.total_prix_TTC ?? Number(prixUniteTTC) * Number(qte);
    }
    const remise = produit?.remise ?? 0;
    const product = {
      produitId: produit?.id,
      libelle: produit?.libelle,
      quantite: Number(qte),
      prixHT: Number(prixUniteHt),
      prixTTC: Number(prixUniteTTC),
      tva: Number(tva),
      remise: Number(remise),
      total_prix_HT: Number(ht).toFixed(3),
      total_prix_TTC: Number(ttc).toFixed(3),
      type: produit.type
    };
    const productList = productsForm.getFieldValue("produit_bon_livraisons")
      ? productsForm.getFieldValue("produit_bon_livraisons")
      : [];
    productList.push(product);
    productsForm.setFieldsValue({ produit_bon_livraisons: productList });
    calculeTotalTaxes();
  };

  const getThemeMode = () => {
    const theme = localStorage.getItem("COLOR_THEME");
    const darkMode = theme === "DARK" ? true : false;
    setDarkMode(darkMode);
  };

  const loadClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      setClients(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  const getClients = async () => {
    setLoading(true);
    const response = await ClientService.getClients();
    if (response.status === 200 || 204) {
      return response.data;
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };
  

  const loadEntreprise = async () => {
    setLoading(true);
    const response = await EntrepriseService.getEntreprise();
    if (response.status === 200 || 204) {
      setEntreprise(response.data);
    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  };

  const clearStates = () => {
    setEditMode(false);
    setUpdateId(null);
  };

  const handleCancel = () => {
    setProductsModal(false);
  };

  // type : 0->qte | 1->prixttc | 2->tva |3->remise
  const priceChanged = (index, type) => {
    const products = productsForm.getFieldValue("produit_bon_livraisons");
    const product = products[index];
    const taxe = product.tva / 100;
    const quantite = product.quantite;
    const remise = product.remise;
    
    let prixTTC = 0
    let prixHT = 0
    let total_ttc = ""
    let total_ht = ""

    if (type == 0 || type == 1){
      prixTTC = product.prixTTC
      total_ttc = Number(prixTTC * quantite).toFixed(3);
      total_ht = (Number(total_ttc) / (1 + taxe)).toFixed(3) 

      products[index].total_prix_TTC = total_ttc;
      products[index].total_prix_HT = total_ht

    }else if (type == 2){
      let lastProductPrixTTC = productList.filter((it)=>it.id == product.produitId)[0]?.produit_vente_en_lignes[0]?.prixTTC
      lastProductPrixTTC = Number(lastProductPrixTTC)
      
      const tvaDiff = (product.tva - 19) / 100
      // console.log("tvaDiff",tvaDiff)
      prixTTC = tvaDiff !== 0 ? product.prixTTC + product.prixTTC * tvaDiff :  lastProductPrixTTC
      // console.log("2",prixTTC)
      total_ttc = Number(prixTTC * quantite).toFixed(3);
      prixTTC = parseFloat((total_ttc / quantite).toFixed(3))

      products[index].prixTTC = prixTTC;
      products[index].total_prix_TTC = total_ttc;

    }else if (type == 3){
      prixTTC = product.prixTTC - (product.prixTTC * (remise / 100))
      prixHT = product.prixHT

      total_ttc = Number(prixTTC * quantite).toFixed(3);
      total_ht = (Number(total_ttc) / (1 + taxe)).toFixed(3) 

      products[index].total_prix_TTC = total_ttc;
    }

    productsForm.setFieldsValue({ products });
    calculeTotalTaxes();
  }

  const calculeTotalTaxes = async (activeFodec) => {
    const isFodecActive = activeFodec ? activeFodec : fodec;
    const products = productsForm.getFieldValue("produit_bon_livraisons");
    const list = [];
    const listGain = [];

    for (const product of products) {
      if (product.tva > 0.1) {
        const base = isFodecActive ? 
          Number(product.total_prix_HT) + Number(product.total_prix_HT * 1) / 100 :
          Number(product.total_prix_HT);
        const montantTaxes = isFodecActive ?
          ((Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100)).toFixed(3) :
          (Number(product.total_prix_HT) * (product.tva / 100)).toFixed(3);
        const taxe = {
          tvaPorucentage: product.tva,
          montantTaxes: montantTaxes,
          base: base,
        };
        list.push(taxe);
      }
      const listProd = productList.length ? productList : await getProducts(); 
      const prod = listProd.find( prod => prod.id === product.produitId);
      const gain_ht = ( product.total_prix_HT * product.quantite  - (prod?.produit_achats[0]?.prixHT * product.quantite)   ).toFixed(3);
      const gain_ttc = (product.prixTTC - prod?.produit_achats[0]?.prixTTC ) * product.quantite;
      const gain = {gain_ht , gain_ttc};
      listGain.push(gain);
    }
    setGain(listGain)
    setListAppliedTaxes(list);
    calculateTotal(isFodecActive);
  };


  const clientChanged = async () => {
    const clientId = productsForm.getFieldValue("client");
    const clientList = clients.length ? clients : await getClients();
    const cli =  clientList.find((client) => client.id === clientId);
    setClient( cli );

  };

  const calculateTotal = (activeFodec) => {
    const isFodecActive = activeFodec ? activeFodec : fodec;
    const products = productsForm.getFieldValue("produit_bon_livraisons");
    const remiseGlobal = productsForm.getFieldValue("remise_global") ? productsForm.getFieldValue("remise_global") : 0;
    const list = [];
    let total_ht = 0;
    let tva = 0;
    let totalTimbreFiscal = prixTotal.totalTimbreFiscal
    let total_ttc = 0;
    let temp_ttc = 0;

    for (const product of products) {
      const montantTaxes = isFodecActive ? 
        (Number(product.total_prix_HT) + Number(product.total_prix_HT * 0.01)) * (product.tva / 100) :
        (Number(product.total_prix_HT) ) * (product.tva / 100) ;
      total_ht += Number(product.total_prix_HT);
      tva += montantTaxes;
      temp_ttc += Number(product.total_prix_TTC)
    }
    total_ht = Number(total_ht) - ( Number(total_ht) *  (Number(remiseGlobal) / 100))

    let fodecMontant = isFodecActive ? Number(total_ht) / 100 : 0;
    total_ttc = Number((temp_ttc + Number(fodecMontant)).toFixed(1));

    let montant_total = Number((Number(total_ttc) - ( Number(total_ttc) *  (Number(remiseGlobal) / 100)) + Number(totalTimbreFiscal)).toFixed(1))
    const total = { montant_total, total_ttc, total_ht, fodec: fodecMontant, tva, totalTimbreFiscal };
    setPrixTotal(total);
    setoldMontantTotal(total.montant_total)

    const final = montant_total.toString().split(".");
    const currencyName = currencys.find(c => c.code === productsForm.getFieldValue("devise"));
    setWrittenTotal("Le compte est arrêté à la somme de " + writtenNumber(final[0]) + " " +currencyName.base + " , " +final[1] + " " + currencyName.sub);

  };

  const getProductId = (index, name) => {
    return productsForm.getFieldValue("produit_bon_livraisons")[index]?.produitId;
  };

  const addBonLivraison = async () => {
    setLoading(true);
    const product = productsForm.getFieldsValue();
    for (const element of product.produit_bon_livraisons) {
      element.clientId = productsForm.getFieldValue("client");
    }
    const bonLivraison = {...product , ...prixTotal }
    bonLivraison.clientId = bonLivraison.client
    bonLivraison.etat = 0;
    bonLivraison.remise_global =  bonLivraison.remise_global ? bonLivraison.remise_global : 0;
    bonLivraison.reste_a_payer = bonLivraison.montant_total
    bonLivraison.paiment_status = -1
    bonLivraison.is_en_ligne = true
    const response = editMode ? 
      await BonLivraisonService.updateBonLivraison(updateId,bonLivraison) 
      : await BonLivraisonService.addBonLivraison(bonLivraison);
    if (response.status === 200 || 204) {
      if(editMode) message.info("Mise à jour avec success");
      else message.info("Bon de commande Ajouter Avec Success");
      history.push("/el/bon_livraisons");

    } else message.error("Une erreur est survenu ! ");
    setLoading(false);
  
  }

  const ProduitColumns = [
    {
      title: "Type",
      dataIndex: "showType",
      key: "1",
      sorter: (a, b) => a.showType.localeCompare(b.showType),
    },
    {
      title: "Libelle",
      dataIndex: "libelle",
      sorter: (a, b) => a.libelle.localeCompare(b.libelle),
    },
    {
      title: "Référence Int",
      dataIndex: "reference_interne",
      sorter: (a, b) => a.reference_interne.localeCompare(b.reference_interne),
    },
    {
      title: "Fournisseur",
      dataIndex: "showFournisseur",
      sorter: (a, b) => a.showFournisseur.localeCompare(b.showFournisseur),
    },{
      title: "Quantité",
      dataIndex: "pqte",
      sorter: (a, b) =>  a.pqte.localeCompare(b.pqte),
    },
    {
      title: "Achat TTC",
      dataIndex: "ttcAchat",
      sorter: (a, b) => a.ttcAchat - b.ttcAchat,
    },
    {
      title: "Vente TTC",
      dataIndex: "ttcVente",
      sorter: (a, b) =>  a.ttcVente.localeCompare(b.ttcVente),
      render: (text, record) => {
        const isProdVenteEnLigne = isProduitWithVenteEnLigne(record)
        return(
          <>  {isProdVenteEnLigne ? 
                text :  
                <div>
                  <Tooltip title="MAJ le Prix vente en ligne pour ce produit">
                    <MinusCircleOutlined style={{color: 'red'}} /> 
                  </Tooltip>
                </div>
              } 
          </>
        )
      } 
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div>
          <Tooltip title="Choisir">
            <Button
              disabled={isSelected(record.id) || !isProduitWithVenteEnLigne(record)}
              className="mx-1"
              type="dashed"
              shape="circle"
              onClick={() => selectProduct(record)}
              icon={<CheckOutlined />}
            />
          </Tooltip>
        </div>
      ),
    },
  ];
  const openProductModal = () => {
    setProductModal(true);
  };
  const onCloseProductModal = async (action) => {
    if (action) {
      const fournisseurId = productsForm.getFieldValue("fournisseur");
      await loadProduits(fournisseurId);
    }
    setProductModal(false);
  };

  const changeCurrency = () => {
    const currency = productsForm.getFieldValue("devise");
    setSelectedCurrency(currency);
  }
  const disableForm = () => {
    return selectedIds.length === 0;
  }

  const handleTimbreChange = (selectedTimbre) => {
    console.log(selectedTimbre)
    setPrixTotal({...prixTotal,
                totalTimbreFiscal:selectedTimbre.toFixed(3),
                montant_total: Number(oldMontantTotal) + selectedTimbre 
              })
  }

  const onSearchProduct = (value) =>{ 
    setLoading(true)
    value = value.toLowerCase()
    const searchResult = productList.filter((it)=> it.libelle.toLowerCase().includes(value))
    setProductSearch(searchResult)      
    setLoading(false)
  }

  const getProductMax = (index) => {
    const product = productsForm.getFieldValue("produit_bon_livraisons");
    const produitId = product[index].produitId;
    const prodStockQuantite = productList.find((it)=> it.id == produitId).stocks[0].quantite
    return prodStockQuantite
  }

  let isReferenceDisabled = () => {
    // if (!blToUpdate.reference | !editMode ) return true
    return true 
  }

  function currencyFormat(num){
    return !isNaN(num) ? Number(num)?.toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num
  }

  return (
    <>
    {Can("18_2") ? (
      <Spin  className="px-5" spinning={isLoading} size="large" >
        <Card className="w-100 card-shadow">
          <Form layout="vertical" initialValues={{ devise: "TND", }} form={productsForm} onFinish={addBonLivraison}>
            <Row>
              <FicheHeader
                moduleName={"Bon De Livraison En Ligne"}
                showClients={isVente}
                entreprise={entreprise}
                clients={clients}
                fournisseurs={fournisseurs}
                selectedClient={() => clientChanged()}
                reloadClients={() => reloadClients()}

              ></FicheHeader>
            </Row>
            <Row>
              {isVente ? (
                <Col span={8} className="px-2 pt-3">
                  <FicheClient client={client}></FicheClient>
                </Col>
              ) : (
                <Col span={8} className="px-2 pt-3">
                  <FicheFournisseur fournisseur={fournisseur}></FicheFournisseur>
                </Col>
              )}
              <Col span={8} offset={8} className="px-2 pt-3">
                <FicheDetails
                  currencyChanged={ ()=>  changeCurrency()}
                  referenceDisabled={isReferenceDisabled()}
                ></FicheDetails>
              </Col>
            </Row>
            <Row>
              <Col span="24" className="px-4">
                <table
                  className={` mb-5 table ${isDarkMode ? "table-dark" : ""} `}
                >
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Libelle</th>
                      <th scope="col">Quantité</th>
                      <th scope="col">Prix Unitaire TTC</th>
                      <th scope="col">TVA</th>
                      <th scope="col">Remise</th>
                      <th scope="col">Total HT</th>
                      <th scope="col">Total TTC</th>
                      <th scope="col" className="text-center color">
                        <Tooltip title="Ajouter un produit">
                          <span
                            className="medium pointer"
                            type="primary"
                            onClick={() => setProductsModal(true)}
                          >
                            <PlusCircleOutlined />
                          </span>
                        </Tooltip>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <Form.List
                      name="produit_bon_livraisons"
                      label="List des addresse de livraisons"
                    >
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map(({ key, name, ...restField }, index) => (
                            <>
                              <tr>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "libelle"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <Input
                                      style={{ width: 180 }}
                                      type={"text"}
                                      bordered={false}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "quantite"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      style={{ width: 120 }}
                                      onChange={() => priceChanged(index,0)}
                                      className="w-125px"
                                      min={0}
                                      max={getProductMax(index)}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "prixTTC"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      onChange={() => priceChanged(index,1)}
                                      // onChange={() => prixttcChanged(index)}
                                      style={{ width: 120 }}
                                      className="w-125px"
                                      min={0}
                                      formatter={(value) => `${value}`}
                                      parser={(value) =>
                                        value.replace("", "")
                                      }
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "tva"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      onChange={() => priceChanged(index,2)} 
                                      // onChange={(val) => priceChangedTVA(index,val)}
                                      style={{ width: 60 }}
                                      min={0}
                                      max={100}
                                      formatter={(value) => `${value}%`}
                                      parser={(value) => value.replace("%", "")}
                                    />
                                  </Form.Item>
                                </td>
                                <td>
                                  <Form.Item
                                    {...restField}
                                    name={[name, "remise"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      onChange={() => priceChanged(index,3)} 
                                      // onChange={() => remiseChanged(index)}
                                      min={0}
                                      max={100}
                                      style={{ width: 60 }}
                                      formatter={(value) => `${value}%`}
                                      parser={(value) => value.replace("%", "")}
                                    />
                                  </Form.Item>
                                </td>
                                <th scope="row" className=" w-90px">
                                  <Form.Item
                                    {...restField}
                                    name={[name, "total_prix_HT"]}
                                    rules={[
                                      {
                                        required: true,
                                        message:
                                          "Champ obligatoire avec maximum de caractère 100 !",
                                      },
                                    ]}
                                  >
                                    <InputNumber
                                      className={`w-125px ${
                                        isDarkMode
                                          ? "total-input-dark"
                                          : "total-input-light"
                                      } `}
                                      bordered={false}
                                      style={{ width: 120 }}
                                      min={0}
                                      disabled
                                      formatter={(value) => currencyFormat(value)}
                                      parser={(value) =>
                                        value.replace("", "")
                                      }
                                    />
                                  </Form.Item>
                                </th>
                                <th scope="row" className="w-90px">
                                  <Form.Item {...restField} name={[name, "total_prix_TTC"]}>
                                    <InputNumber
                                      className={`w-125px ${
                                        isDarkMode
                                          ? "total-input-dark"
                                          : "total-input-light"
                                      } `}
                                      style={{ width: 120 }}
                                      bordered={false}
                                      min={0}
                                      disabled
                                      formatter={(value) => currencyFormat(value)}
                                      parser={(value) =>
                                        value.replace("", "")
                                      }
                                    />
                                  </Form.Item>
                                </th>
                                <td className="text-center red-flag pt-1 w-90px">
                                  <Tooltip title="Supprimer le produit">
                                    <span
                                      className="medium pointer"
                                      type="primary"
                                      onClick={() => {
                                        removeElement(getProductId(index));
                                        remove(name);
                                        calculeTotalTaxes();
                                      }}
                                    >
                                      <DeleteOutlined />
                                    </span>
                                  </Tooltip>
                                </td>
                              </tr>
                            </>
                          ))}
                        </>
                      )}
                    </Form.List>
                  </tbody>
                </table>
              </Col>
              <Col span={7} className="px-2" >
                <Form.Item 
                  name="produitToSelect"
                >
                  <Select
                    size="large" 
                    allowClear
                    showSearch
                    placeholder="produit (quantite en stock)"
                    onChange={() => addProductOnSelect()}
                    filterOption={(inputValue, option) =>
                      option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 
                      || option.props.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                    }
                  >
                    {selectingProductList?.map(produit=> (
                      isProduitWithVenteEnLigne(produit) 
                        ? <Select.Option key={produit.id}>{produit.libelle + " " + "(" +produit.qte+ ")" }</Select.Option>
                        : <Select.Option disabled={true} key={produit.id}> <Tooltip title={"MAJ le Prix vente en ligne pour ce produit"}> {produit.libelle + " " + "(" +produit.qte+ ")" } </Tooltip></Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} offset={16} className="px-4">
                <table className={`table ${isDarkMode ? "table-dark" : ""} `}>
                  <thead>
                    <tr>
                      <th scope="col"  className="pb-3">Remise : </th>
                      <th scope="col">
                        <Form.Item name="remise_global" initialValue={0} className="my-0 py-1">
                          <InputNumber
                            onChange={() => calculateTotal()}
                            style={{ width: "100%" }}
                            formatter={(value) => `${value}%`}
                            parser={(value) => value.replace("%", "")}
                            min={0}
                            max={100}
                          />
                        </Form.Item>
                      </th>
                    </tr>
                  </thead>
                </table>
              </Col>
              <FicheTableGain 
               selectedCurrency={selectedCurrency} 
                gains={gains} 
                isDarkMode={isDarkMode}
              ></FicheTableGain>
              <FicheTableTva
                selectedCurrency={selectedCurrency} 
                isDarkMode={isDarkMode}
                appliedTaxes={appliedTaxes}
              ></FicheTableTva>
                  <FicheTableTotaux
            fodec={fodec}
                selectedCurrency={selectedCurrency} 
                isDarkMode={isDarkMode}
                prixTotal={prixTotal}
                timbreChange={handleTimbreChange}
              ></FicheTableTotaux>
            </Row>
            <Divider className="text-capitalize">{writtenTotal}</Divider>

            <FicheFooter entreprise={entreprise}></FicheFooter>
  
            <Row className="d-flex flex-row-reverse pt-5 pb-2">
              <Button 
                className="mx-2" 
                type="primary"
                onClick={productsForm.submit}
                disabled={disableForm()}

              >
                Enregistrer
              </Button>
              
            </Row>
          </Form>
        </Card>
  
        <Modal
          visible={productsModal}
          getContainer={false}
          footer={null} 
          onCancel={handleCancel}
          confirmLoading={isLoading}
          title="Liste des Produits"
          width={1000}
        >
          <Row>
            <Col span={6}  offset={8} >
              <Search
                placeholder="Libelle"
                onSearch={onSearchProduct}
                style={{
                  width: 400,
                }}
              />
            </Col>
          </Row>
          <br />
          <Spin spinning={isLoading} size="large">
              <AppTable data={productSearch} columns={ProduitColumns} />
          </Spin>
        </Modal>
        <AddProductModalForm showModal={productModal} productAdded={(action) => onCloseProductModal(action)}></AddProductModalForm>

      </Spin >
      ) : (
        <NotAuthorized></NotAuthorized>
      )}
    </>
  );
}

export default BonLivraisonEnLigne