import { Col, Row } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import FactureService from "../../services/factureVente.service";

function BonCommandeExtraData({ bonCommandeId,montantTotal }) {
    const [factures, setFactures] = useState([]);
    const [status , setStatus] = useState({});
    const [noData,setNodata] = useState(true);

    useEffect(() => {
        if (bonCommandeId) getData();

    }, []);

    const getData = async () => {
        const response = await FactureService.getFacturesDataByBnCommande( bonCommandeId );
        if( response.data.length ){
            setFactures(response.data);
            getFacturationStatus(response.data);
            setNodata(false);
        }
    };

    const getFacturationStatus = (factures) => {
        let totalFactures = 0;
        let nbrFacture = factures.length;
        for (const facture of factures) totalFactures += Number(facture.montant_total);
        let restToFacture = Number(montantTotal - totalFactures).toFixed(3);
        restToFacture = restToFacture > 0 ? restToFacture : "0"
        setStatus({
            totalFactures: Number(totalFactures).toFixed(3),
            nbrFacture,
            restToFacture,
            devise : factures[0].devise
        });
        
    }
    const getFactureStatus = (status) => {
        switch (status) {
        case 1:
            return "Payé";
        case 2:
            return "Payé Partiellement";
        default:
            return "Non Payé";
        }
    };
    const calculateReste = (total , paiements) => {
        let totalPaye = 0;
        for (const paiement of paiements) totalPaye += paiement.montant; 
        return Number(total - totalPaye).toFixed(3);
    };
    return (
        <>
            {!noData ? (
                <>
                <Row>
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Nombre total des factures : {status.nbrFacture} </th>
                                <th>Montant facturé : {status.totalFactures} {status.devise} </th>
                                <th>Reste à facturer : {status.restToFacture} {status.devise}</th>

                            </tr>
                        </thead>
                    </table>
                    <br />


                    <table className="table">
                    <thead>
                        <tr>
                        <th>Facture</th>
                        <th>Date</th>
                        <th>Montant HT</th>
                        <th>Montant TTC</th>
                        <th>Etat Paiement</th>
                        <th>Reste à payer </th>
                        </tr>
                    </thead>
                    <tbody>
                        {factures &&
                        factures.map((facture) => (
                            <tr>
                                <td> {facture.reference} </td>
                                <td> {moment(facture.reference).format("YYYY-MM-DD")}</td>
                                <td> {Number(facture.total_ht).toFixed(3)} {facture.devise} </td>
                                <td> {facture.montant_total} {facture.devise} </td>
                                <td> {getFactureStatus(facture.paiement_status)} </td>
                                <td> {calculateReste(facture.montant_total,facture.paiement_ventes)} {facture.devise} </td>
                            </tr>
                        ))}
                    </tbody>
                    </table>
                </Row>
                </>
            ) : (
                <Row>
                    <b className="text-center">Ce bon de commande n'est pas lié à des factures actives.</b>
                </Row>
            )}
        </>
    );
}
export default BonCommandeExtraData;
