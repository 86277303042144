import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addEmploye = ( employe ) => {
  return axios.post(API_URL + "/employe/" , employe ,{ headers: authHeader() });
};

const getEmployes = () => {
  return axios.get(API_URL + "/employe/" , { headers: authHeader() });
};

const updateEmploye = (id , employe ) => {
  return axios.put(API_URL + "/employe/" + id , employe, { headers: authHeader() });
};

const deleteEmploye = (id) => {
  return axios.delete(API_URL + "/employe/" + id , { headers: authHeader() });
};

const getEmployesNames = () => {
  return axios.get(API_URL + "/employe/names" , { headers: authHeader() });
};



const exportsMethodes = {
  addEmploye,
  getEmployes,
  updateEmploye,
  deleteEmploye,
  getEmployesNames
};
export default exportsMethodes;