import authHeader from "./auth-header";

import axios from "axios";

import { environment } from "../env/config.env";

const API_URL = environment.api;

const addCategorie = ( categorie ) => {
  return axios.post(API_URL + "/categorie/" , categorie ,{ headers: authHeader() });
};

const getCategories = () => {
  return axios.get(API_URL + "/categorie/" , { headers: authHeader() });
};
const getActiveCategories = () => {
  return axios.get(API_URL + "/categorie/active" , { headers: authHeader() });
};
const updateCategorie = (id , categorie ) => {
  return axios.put(API_URL + "/categorie/" + id , categorie, { headers: authHeader() });
};
const deleteCategorie = (id) => {
  return axios.delete(API_URL + "/categorie/" + id , { headers: authHeader() });
};

const exportsMethodes = {
  addCategorie,
  getCategories,
  updateCategorie,
  deleteCategorie,
  getActiveCategories
};
export default exportsMethodes;