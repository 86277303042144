import { Col, Image } from "antd";
import React from "react";
import logo from "../../../assets/images/logo.png";

function FicheTransfertHeader({
    moduleName,
    entreprise,
}) {

  

  return (
    <>
      <Col span={4} className="text-center px-2">
        {/* <image src="./assets/images/logo.png" alt="dslq" width="50px" height={"50px"} /> */}
        <Image src={logo} preview={false} width={"100%"} height={"175px"} />
      </Col>
      <Col span={16}>
        <div className="px-3 mt-3 ligne-height-25">
          <span>
            <strong className="description-text">
              {" "}
              {entreprise[0]?.raison_social}{" "}
            </strong>{" "}
          </span>{" "}
          <br />
          <span>
            <strong> MF : </strong>
          </span>
          <span className="description-text">
            {" "}
            {entreprise[0]?.matricule_fiscal}
          </span>{" "}
          <br />
          <span>
            <strong> Téléphone  :</strong>{" "}
          </span>
          <span className="description-text"> {entreprise[0]?.telephone}</span>{" "}
          <br />
          <span>
            <strong> Adresse :</strong>
          </span>
          <span className="description-text"> {entreprise[0]?.adresse} </span>{" "}
          <br />
          <span>
            <strong> E-mail :</strong>{" "}
          </span>
          <span className="description-text"> {entreprise[0]?.email} </span> <br />
        </div>
      </Col>      
      <Col span={4}>
        <div className="w-100 text-right">
          <h3>{moduleName}</h3>
        </div>
      </Col>
      <Col span={8} className="height-50">

      </Col>
      <Col span={8} offset={8} className="px-2">
        <h4 className="pt-5">Détails document</h4>
      </Col>

      <Col span={8}>
        <div className="border"></div>
      </Col>
      <Col span={8} offset={8}>
        <div className="border"></div>
      </Col>
    </>
  );
}
export default FicheTransfertHeader;
