import { Card, message, Spin, Input, Tooltip, Button, Form, Row, Col, InputNumber, Modal } from "antd";
  import { EditOutlined } from "@ant-design/icons";
  import React, { useEffect, useState } from "react";
  import ConfectionService from "../../../../services/confection.service";
  import ProductService from "../../../../services/produit.service";
  import Can from "../../../../security/Can";
  
  
  import TableFilters from "../../../uiHelpers/TableFilters";
    
    const StockConfection = () => {
      const [stockForm] = Form.useForm();
      const [isLoading, setLoading] = useState(false);
      const [dataTable, setDataTable] = useState([]);     
      const [produitsComposites, setProduitsComposites] = useState([]);     
  
      const [sommePrixTotale, setSommePrixTotale] = useState(0);     
      const [sommeCoutTotale, setSommeCoutTotale] = useState(0); 
      
      const [updateModal, setUpdateModal] = useState(false);
      const [updateId, setUpdateId] = useState();
  
      useEffect(() => {
        const fetch = async () => { 
        await loadProduitventes()
        await loadStock();
        }
        fetch()
        return () => {
          clearStates();
        };
      }, []);
    
      const clearStates = () => {
        setDataTable([]);
      };
      const loadStock = async () => {
        setLoading(true);
        const PCompositess = await ProductService.getProduitComposites()
        const response = await ConfectionService.loadStock();
        if (response.status === 200 || 204) initTableData(response.data,PCompositess.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
      };

      const loadProduitventes = async () => {
        setLoading(true);
        const response = await ProductService.getProduitComposites()
        if (response.status === 200 || 204) setProduitsComposites(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
      }
  
      const initTableData = (data, produitsComposites) => {
        console.log(produitsComposites);
        for (const element of data){ 
          const prod = produitsComposites.filter((e) => e?.produitId === element?.produitId )
          element.showProduit = element?.produit?.libelle;
          element.prixUnit = prod ? getPrixUnit(prod) : "";
          element.prixTotal = prod ? getPrixTotal(element) : 0
          //TODO : coutTotal = prixUnit * quantite or quantitePremierChoix ?
          element.coutTotal = prod ? (element.prixUnit ? Number((element.prixUnit * element.quantitePremierChoix).toFixed(3)) : 0) : 0
          //element.quantite = element.quantitePremierChoix + element.quantiteDeuxiemeChoix                                 
          setSommePrixTotale((prev) => parseFloat(prev) + parseFloat(element.prixTotal * element.quantitePremierChoix))
          setSommeCoutTotale((prev) => prev + element.coutTotal)     
              
          element.prixUnit = currencyFormat(element.prixUnit)
          element.prixTotal = currencyFormat(element.prixTotal)
          element.coutTotal = currencyFormat(element.coutTotal)                   
        }
        sortByUpdatedAtd(data)
        setDataTable(data)
        console.log(data)
      };
    
      const sortByUpdatedAtd = (data) => {
        return data.sort((a,b)=>{
             return new Date(a.updatedAt).getTime() - 
             new Date(b.updatedAt).getTime()
         }).reverse();
      }

      const getPrixUnit = (produitsComposites) => {
        let prixUnit = 0
        for (let element of produitsComposites){
          prixUnit += Number(element.prixTotalTTC)
        }
        return Math.round(prixUnit * 100) / 100
      }

      const getPrixTotal = (element) => {
        return element?.produit ? Number(element?.produit.produit_ventes[0].prixTTC) : 0
      }

      const updateMode = (record) => {
        setUpdateModal(true);
        stockForm.setFieldsValue(record);
        setUpdateId(record.id);
      };

      const updateStock = async (values) => {
        setLoading(true);
        const response = await ConfectionService.update(updateId, values);
        if (response.status === 200 || 204)
          message.info("Mise à jour avec success");
        else message.error("Une erreur est survenu ! ");
        closingModal();
      };

      const closingModal = () => {
        setUpdateModal(false);
        setLoading(false);
        clearData();
        loadStock();
      };

      const handleCancel = () => {
        setUpdateModal(false);
        clearData();
      };

      const clearData = () => {
        stockForm.resetFields();
        setUpdateId(null);
      };

      const colums = [
        {
          title: "Produit",
          dataIndex: "showProduit",
          sorter: (a, b) => a.showProduit < b.showProduit,
        },
        {
          title: "Quantité Totale",
          dataIndex: "quantite",
          sorter: (a, b) => a.quantite - b.quantite,
        },
        {
          title: "Quantité Premier Choix",
          dataIndex: "quantitePremierChoix",
          sorter: (a, b) => a.quantitePremierChoix -  b.quantitePremierChoix,
        },
        {
          title: "Quantité Deuixeme Choix",
          dataIndex: "quantiteDeuxiemeChoix",
          sorter: (a, b) => a.quantiteDeuxiemeChoix - b.quantiteDeuxiemeChoix,
        },
        {
          title: "Cout Unit TTC",
          dataIndex: "prixUnit",
          sorter: (a, b) => a.prixUnit - b.prixUnit,
        },
        {
          title: "Prix Vente TTC ",
          dataIndex: "prixTotal",
          sorter: (a, b) => a.prixTotal - b.prixTotal,
        },
        {
        title: "Cout Total TTC",
        dataIndex: "coutTotal",
        sorter: (a, b) => a.coutTotal - b.coutTotal,
        },
        {
        title: "Action",
        key: "action",
        render: (text, record) => (
          <div>
            <Tooltip title="Mettre à jour">
              <Button
                className="mx-1"
                type="dashed"
                disabled={!Can("20_3")}
                shape="circle"
                onClick={() => updateMode(record)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </div>
          ),
        },
      ];
    
      function currencyFormat(num) {
        return  !isNaN(num) ? Number(num).toFixed(3).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num
      }

      const handleQuantiteChange = (e) => {
        stockForm.setFieldsValue({quantite : e.target.value})
      }

      return (
          <>
            <Spin spinning={isLoading} size="large">
              <Card title={"Stock Produit "} >
                  <div className="d-flex justify-content-between">
                    <h6> Cout des PF en stock  :       <input className="text-danger text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(sommeCoutTotale)} /> </h6>
                    <h6> Prix vente des PF  : <input className="text-success text-center w-25 border-0 bg-transparent" disabled type="text" value={currencyFormat(sommePrixTotale)} /> </h6>
                  </div>
                  <br />
                  <TableFilters data={dataTable} columns={colums} />
                                    
              </Card>
            </Spin>

            <Form layout="vertical" form={stockForm} onFinish={updateStock}>
              <Modal
                visible={updateModal}
                onOk={stockForm.submit}
                onCancel={handleCancel}
                getContainer={false}
                confirmLoading={isLoading}
                okText="Enregistrer"
                cancelText="Annuler"
                centered
                width={900}
                title={"Modifier la quantité "}
              >
                <Row>
                  <Col span={8} className="px-2">
                    <Form.Item label="Libelle" name="showProduit">
                      <Input
                        disabled
                        size="large"
                        type="text"
                        maxLength={100}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Quantité Total"
                      name="quantite"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire!",
                        },
                      ]}
                    >
                      <Input  
                        disabled 
                        size="large" 
                        min={0} />
                    </Form.Item>
                  </Col>
                  {/* <Col span={8} className="px-2">
                    <Form.Item
                      label="Quantité 2eme Choix"
                      name="quantiteDeuxiemeChoix"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire!",
                        },
                      ]}
                    >
                      <Input size="large" min={0} />
                    </Form.Item>
                  </Col> */}
                  <Col span={8} className="px-2">
                    <Form.Item
                      label="Quantité 1ere Choix"
                      name="quantitePremierChoix"
                      rules={[
                        {
                          required: true,
                          message: "Champ obligatoire!",
                        },
                      ]}
                    >
                      <Input 
                        onChange={(e)=> handleQuantiteChange(e)}
                        size="large" 
                        min={0} />
                    </Form.Item>
                  </Col>
                </Row>
              </Modal>
            </Form>

          </>
      );
    };
    export default StockConfection;
    