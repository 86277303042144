import { EditOutlined, ToolOutlined } from "@ant-design/icons";
import { Button, Card, Col, Dropdown, Form, Input, InputNumber, Menu, message, Modal, Row, Select, Spin, Tag, Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import typeConfection from "../../../../helpers/typeBroderie";

import Can from "../../../../security/Can";
import ConfectionService from "../../../../services/confection.service";

import assemblageService from "../../../../services/assemblage.service";

import typeCoupeService from "../../../../services/typeCoupe.service";
import typeTissusService from "../../../../services/typeTissus.service";

import AppTable from "../../../uiHelpers/AppTabe";
    
import clientService from "../../../../services/client.service";
import fournisseurService from "../../../../services/fournisseur.service";
import produitService from "../../../../services/produit.service";
import referenceBroderieService from "../../../../services/referenceBroderie.service";

import moment from "moment";
import 'moment/locale/fr'
moment.locale('fr')

const Confection = () => {
    const [confectionForm] = Form.useForm();
    const [confectionModal, setConfectionModal] = useState(false);
    const [validateConfectionModal, setValidateConfectionModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [updateId, setUpdateId] = useState();
    const [dataTable, setDataTable] = useState([]);
    const [typeCoupe, setTypeCoupe] = useState([]);
    const [typeTissu, setTypeTissu] = useState([]);

    const [selectedType , setSelectedType] = useState(false);
    const [referencesBroderies, setReferencesBroderie] = useState([]);
    const [produits, setProduits] = useState([]);
    const [clients, setClients] = useState([]);
    const [fournisseurs, setFournisseurs] = useState([]);

    useEffect(() => {
    loadConfections();
    loadTypeCoupes();
    loadTypeTissus();
    loadProduits();
    loadReferencesBrodrie();
    loadFournisseurs();
    loadClients();


    return () => {
        clearStates();
    };
    }, []);

    const clearStates = () => {
    setDataTable([]);
    setEditMode(false);
    setUpdateId(null);
    };

    const loadConfections = async () => {
    setLoading(true);
    const response = await ConfectionService.getConfections();
    const tCoup = await typeCoupeService.getTypeCoupes();
    const tTissus = await typeTissusService.getTypeTissus();
    if (response.status === 200 || 204) initTableData(response.data, tCoup.data, tTissus.data);
    else message.error("Une erreur est survenu ! ");
    setLoading(false);
    };

    const loadTypeCoupes = async () => {
        setLoading(true);
        const response = await typeCoupeService.getTypeCoupes();
        if (response.status === 200 || 204) setTypeCoupe(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };
    
    const loadTypeTissus = async () => {
        setLoading(true);
        const response = await typeTissusService.getTypeTissus();
        if (response.status === 200 || 204) setTypeTissu(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };


    const loadReferencesBrodrie = async () => {
        setLoading(true);
        const response = await referenceBroderieService.getReferenceBroderies();
        if (response.status === 200 || 204) setReferencesBroderie(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
      };
    const loadFournisseurs = async () => {
        setLoading(true);
        const response = await fournisseurService.getFournisseurs();
        if (response.status === 200 || 204) setFournisseurs(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };

    const loadClients = async () => {
        setLoading(true);
        const response = await clientService.getClients();
        if (response.status === 200 || 204) setClients(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };

    const loadProduits = async () => {
        setLoading(true);
        const response = await produitService.getProduitFini();
        if (response.status === 200 || 204) setProduits(response.data);
        else message.error("Une erreur est survenu ! ");
        setLoading(false);
    };

    const initTableData = (data, tCoupe, tTissus) => {
        for (const element of data) {
            element.showType = element.type === 1 ? "Suite Bon Commande" : "Interne"
            element.bonCommande = element?.bon_commande_vente?.reference ?? "N/A"
            element.showProduit = element?.produit?.libelle;

            element.typeCoupe = tCoupe.filter((e) => e?.id === element?.prodTypeCoupeId)[0]?.nom ?? ""
            element.typeTissus = tTissus.filter((e) => e?.id === element?.prodTypeTissuId )[0]?.nom ?? ""
            element.createdAt = moment(element.createdAt).format("YYYY-MM-DD")
        }
        var temp = sortByCreatedAt(data)
        setDataTable(temp)
    };



    const showConfectionModal = () => {
      setConfectionModal(true);
    };

    const handleCancel = () => {
        setConfectionModal(false);
        setValidateConfectionModal(false);
        clearData();
    };

    const sortByCreatedAt = (data) => {
        return data.sort((a,b)=>{
             return new Date(a.createdAt).getTime() - 
             new Date(b.createdAt).getTime()
         }).reverse();
     }

    const addConfection = async (values) => {
        // const prodId = editMode && produits.find((it) => it.libelle == values.showProduit )?.id
        // values.produitId = prodId ? prodId : Number(values.showProduit) 
        const form = confectionForm.getFieldsValue();
        setLoading(true);
        
        values.etat = 0

        if( values?.qteTotal )
            values.etat = 2; 
            
        if (values?.qteTotal >= values?.nombreObjectif )
            values.etat = 1;

        console.log("values",values)     
        const response = editMode
        ? await ConfectionService.updateConfection(updateId, values)
        : await ConfectionService.addConfection(values);
        if (response.status === 200 || 204) {
        if (editMode) message.info("Mise à jour avec success");
        else message.info("Ordre de confection ajouter avec success");
        } else message.error("Une erreur est survenu ! ");

        closingModal();
    };

    const closingModal = async () => {
        await loadConfections();
        setConfectionModal(false);
        setValidateConfectionModal(false);
        clearData();
    };

    const clearData = () => {
        confectionForm.resetFields();
        confectionForm.setFieldsValue({ type: false });
        setEditMode(false);
        setUpdateId(null);
        setSelectedType(false);
        setLoading(false);
    };

    const updateMode = (record) => {
        setUpdateId(record.id);
        setEditMode(true);
        confectionForm.setFieldsValue(record);
        setConfectionModal(true);
    }

    const showProdModal = (record) => {
        setUpdateId(record.id);
        setEditMode(true);
        confectionForm.setFieldsValue(record);  
        confectionForm.setFieldsValue({ oldTotal: record.qteTotal })
        setValidateConfectionModal(true);

    }

    const loadStockQte = async () => {
        setLoading(true);
        const config = confectionForm.getFieldsValue();

        if( config.prodTypeCoupeId !== undefined && config.prodTypeTissuId !== undefined ){
            const response = await assemblageService.loadStockQte({prodTypeCoupeId:config.prodTypeCoupeId, prodTypeTissuId: config.prodTypeTissuId, prodReferenceBroderieId: config.prodReferenceBroderieId, produitId:config.produitId});
            const qteStock = response?.data?.quantite ?? 0;
            confectionForm.setFieldsValue({qteStock});
        }
        setLoading(false);
    }

    const showCommandeField = () => {
        const config = confectionForm.getFieldsValue();
        setSelectedType(config.type);
    }


    const caculateDeuiemeChoix = () => {
        const form = confectionForm.getFieldsValue();
        //const quantiteDeuxiemeChoix = form.qteTotal - form.quantitePremierChoix;
        const quantiteDeuxiemeChoix = form.qteProduite ? form.qteProduite - form.quantitePremierChoix : 0;
        confectionForm.setFieldsValue({quantiteDeuxiemeChoix});
    }

    const caculatePremierChoix = () => {
        const form = confectionForm.getFieldsValue();
        //const quantitePremierChoix = form.qteTotal - form.quantiteDeuxiemeChoix;
        const quantitePremierChoix = form.qteProduite ? form.qteProduite - form.quantiteDeuxiemeChoix : 0;
        confectionForm.setFieldsValue({quantitePremierChoix}); 
    }

    const getMaxRetour = () => {
        return confectionForm.getFieldValue("nombreObjectif") - confectionForm.getFieldValue("qteTotal") 
    }

    const getMaxRealise = () => {
        return confectionForm.getFieldValue("nombreObjectif");
    }

    const changeOrdreConfectionStatus = async ( record , etat ) => {
        setLoading(true);
        await ConfectionService.updateOdreConfectionStatus(record.id, {etat});
        closingModal();

        setLoading(false);
    }


    const produitChanged = () => {
        const config = confectionForm.getFieldsValue();
        if (config.produitId){
            const produit = produits.find( (e) => e.id === Number(config.produitId) );
            confectionForm.setFieldsValue({
            prodTypeCoupeId: produit.prodTypeCoupeId,
            prodTypeTissuId: produit.prodTypeTissuId,
            prodReferenceBroderieId: produit.prodReferenceBroderieId,
            })
            loadStockQte()
        }
    }
    const quantiteProdChanged = () => {
        const config = confectionForm.getFieldsValue();
        confectionForm.setFieldsValue({ qteTotal: config.oldTotal + config.qteProduite })
        caculatePremierChoix()
        caculateDeuiemeChoix()
    }
    const colums = [
        {
            title: "Numéro",
            dataIndex: "id",
            sorter: (a, b) => a.id - b.id,
        },
        {
            title: "Type Ordre de Confection",
            dataIndex: "showType",
            sorter: (a, b) => a.showType.localeCompare(b.showType),
        },
        {
            title: "Type Coupe",
            dataIndex: "typeCoupe",
            sorter: (a, b) => a.showType.localeCompare(b.showType),
        },
        {
            title: "Type Tissus",
            dataIndex: "typeTissus",
            sorter: (a, b) => a.showType.localeCompare(b.showType),
        },
        {
            title: "Produit",
            dataIndex: "showProduit",
            sorter: (a, b) => a.showProduit.localeCompare(b.showProduit),
        },
        {
            title: "Quantité objectif",
            dataIndex: "nombreObjectif",
            sorter: (a, b) => a.nombreObjectif.localeCompare(b.nombreObjectif),
        },
        {
            title: "Quantité Produite",
            dataIndex: "bonCommande",
            sorter: (a, b) => a.bonCommande.localeCompare(b.bonCommande),
        },
        {
            title: "Quantité ",
            dataIndex: "qteRetourConfection",
            sorter: (a, b) => a.bonCommande.localeCompare(b.bonCommande),
        },
        {
            title: "Date de Creation",
            dataIndex: "createdAt",
            sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
        },
        {
            title: "Etat",
            dataIndex: "etat",
            width: "20%",
            render: (text, record) => (
              <>
                { record.etat === 0 &&
                    <>
                        <Dropdown.Button overlay={
                            <Menu>
                                <Menu.Item className="px-5" key="1" onClick={()=> changeOrdreConfectionStatus(record ,2)}>Activer</Menu.Item>
                                <Menu.Item className="px-5" key="4" onClick={()=> changeOrdreConfectionStatus(record ,-1)}>Annuler</Menu.Item>
                            </Menu>
                        }> Confection en Attente </Dropdown.Button>
                    </>
                }
                {record.etat === 1 && 
                  <Tag color="#2db7f5">Validé</Tag>
                }
                {record.etat === 2 && 
                  <Tag color="#87d068">Production & QC En cours</Tag>
                }
                {record.etat === -1 && 
                  <Tag color="#f50">Annulé</Tag>
                }
              </>
            ),
            sorter: (a, b) => a.etat - b.etat,
        },
        {
            title: "Actions",
            key: "action",
            width: "15%",
            render: (text, record) => (
            <div>
                <Tooltip title="Production et Controle Qualité">
                    <Button
                        className="mx-1"
                        type="dashed"
                        shape="circle"
                        disabled={record.etat !== 2 }
                        onClick={() => showProdModal(record)}
                        icon={<ToolOutlined />}
                        />
                </Tooltip>
                { record.etat === 0 &&
                    <Tooltip title="Mettre à jour">
                        <Button
                            className="mx-1"
                            type="dashed"
                            shape="circle"
                            onClick={() => updateMode(record)}
                            icon={<EditOutlined />}
                        />
                    </Tooltip>
                }
            </div>
            ),
        },
    ];

    return (
        <>
            <Spin spinning={isLoading} size="large">
                <Card title={"Confection"} extra={
                    <Button className="mx-2" shape="round" type="primary" disabled={!Can("9_2")} onClick={() => showConfectionModal()} >
                        Ajouter un ordre de Confection
                    </Button>
                }>
    
                    <AppTable data={dataTable} columns={colums} />
                    <Form layout="vertical" form={confectionForm} onFinish={addConfection} disabled={isLoading} initialValues={{type:3}}>
                        <Modal
                            destroyOnClose={true}
                            visible={confectionModal}
                            onOk={confectionForm.submit}
                            onCancel={handleCancel}
                            getContainer={false} okText="Enregistrer" cancelText="Annuler" centered width={1200}
                            confirmLoading={isLoading}
                            title={ editMode
                                ? "Modifier l'ordre de confection"
                                : "Ajouter Un ordre de confection"
                            }
                        >

                            <Row>
                                <Col span={8} className="px-2">
                                    <Form.Item label="Type d'ordre de confection" name="type"
                                        rules={[ {
                                            required: true,
                                            message:"Champ obligatoire !",
                                        }]}
                                    >
                                        <Select 
                                        size="large"  
                                        onChange={() => showCommandeField()}
                                        dropdownStyle = {{ position: "fixed" }}
                                        >
                                            {typeConfection &&
                                                typeConfection.map((c) => (
                                                    <Select.Option key={c.id} value={c.id} >
                                                    {c.type}  
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8} className="px-2" >
                                    <Form.Item 
                                        label="Produit" name="produitId"
                                        rules={[ { required: true, message: "Champ obligatoire !" }]}
                                    >
                                         <Select
                                            size="large" 
                                            allowClear
                                            showSearch
                                            dropdownStyle = {{ position: "fixed" }}
                                            onChange={() => produitChanged()}
                                            filterOption={(inputValue, option) =>
                                            option.children.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0 
                                            || option.value.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0
                                            }
                                            >
                                                {produits.map(option=> (
                                                    <Select.Option key={option.id}>{option.libelle}</Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                {
                                    selectedType === 1 &&
                                    <Col span={8} className="px-2" >
                                        <Form.Item 
                                        label="Client" name="clientId"
                                        rules={[ { required: true, message: "Champ obligatoire !" }]}
                                        >
                                            <Select size="large">
                                                {clients &&
                                                    clients.map((c) => (
                                                        <Select.Option key={c.id} value={c.id} >
                                                        {c.nom} {c.prenom}  
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                                {
                                    selectedType === 2 &&
                                    <Col span={8} className="px-2" >
                                        <Form.Item 
                                        label="Fournisseur" name="fournisseurId"
                                        rules={[ { required: true, message: "Champ obligatoire !" }]}
                                        >
                                            <Select size="large">
                                                {fournisseurs &&
                                                    fournisseurs.map((c) => (
                                                    <Select.Option key={c.id} value={c.id} >
                                                        {c.nom} {c.prenom}  
                                                    </Select.Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                }
                            </Row>
                            <Row>
                                <Col span={6} className="px-2">
                                    <Form.Item
                                        label="Type de Tissus"
                                        name="prodTypeTissuId"
                                        rules={[
                                            {required :true , message : "Champ obligatoire !"},
                                        ]}
                                    >
                                        <Select size="large" disabled>
                                            {typeTissu &&
                                                typeTissu.map((c) => (
                                                    <Select.Option
                                                        key={c.id}
                                                        value={c.id}
                                                    >
                                                    {c.nom}  
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="px-2">
                                    <Form.Item
                                        label="Type de Coupe"
                                        name="prodTypeCoupeId"
                                        rules={[{ required: true, message: "Champ obligatoire !" }]}
                                    >
                                        <Select size="large" disabled>
                                            {typeCoupe &&
                                                typeCoupe.map((c) => (
                                                    <Select.Option key={c.id} value={c.id}>
                                                    {c.nom}  
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="px-2">
                                    <Form.Item
                                        label="Référence Broderie"
                                        name="prodReferenceBroderieId"
                                    >
                                    <Select size="large"  disabled={true}>
                                        {referencesBroderies.map((m) => (
                                        <Select.Option key={m.id} value={m.id}>
                                            {m.nom}
                                        </Select.Option>
                                        ))}
                                    </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={3} className="px-2">
                                    <Form.Item
                                        label="Qte Stock "
                                        name="qteStock"
                                        rules={[{
                                            required: true,
                                            message: "Champ obligatoire !",
                                        },
                                    ]}
                                    >
                                        <Input size="large" type="text" disabled />
                                    </Form.Item>
                                </Col>
                                <Col span={3} className="px-2">
                                    <Form.Item
                                        label="Qte Objectif"
                                        name="nombreObjectif"
                                        rules={[{
                                            required: true,
                                            message: "Champ obligatoire !",
                                        },
                                    ]}
                                    >
                                        <Input size="large" type="text" max={confectionForm.getFieldValue("qteStock")} />
                                    </Form.Item>
                                </Col>

                            </Row>

                        </Modal>
                    </Form>


                    <Form layout="vertical" form={confectionForm} onFinish={addConfection} disabled={isLoading}>
                        <Modal
                            destroyOnClose={true}
                            visible={validateConfectionModal}
                            onOk={confectionForm.submit}
                            onCancel={handleCancel}
                            getContainer={false} okText="Enregistrer" cancelText="Annuler" centered width={1200}
                            confirmLoading={isLoading}
                            title={ editMode
                                ? "Modifier l'ordre de confection"
                                : "Ajouter Un ordre de confection"
                            }
                        >
                            <Row>
                                <Col span={6} className="px-2">
                                    <Form.Item
                                        label="Qte Objectif"
                                        name="nombreObjectif"
                                        rules={[{
                                            required: true,
                                            message: "Champ obligatoire !",
                                        },
                                    ]}
                                    >
                                    <Input disabled={true} size="large" type="text" max={confectionForm.getFieldValue("qteStock")} />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="px-2">
                                    <Form.Item
                                        label="Quantité Totale Réaliser"
                                        name="qteTotal"
                                        rules={[ {required :true , message : "Champ obligatoire !"} ]}
                                    >
                                        <InputNumber disabled size="large" style={{ width: "100%" }} max={() => getMaxRealise()}  />
                                    </Form.Item>
                                    <Form.Item style={{visibility: "hidden", width:"0px", height:"0px",margin:"0px",padding:"0px" }} name="oldTotal" >
                                        <InputNumber size="large" style={{ width: "100%" }} min={0}  />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="px-2">
                                    <Form.Item
                                        label="Nouvelle Quantité Produite"
                                        name="qteProduite"
                                        rules={[ {required :true , message : "Champ obligatoire !"} ]}
                                    >
                                        <InputNumber size="large" style={{ width: "100%" }} onChange={() => quantiteProdChanged()}  />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="px-2">
                                    <Form.Item
                                        label="Quantité Retour confection"
                                        name="qteRetourConfection"
                                        rules={[ {required :true , message : "Champ obligatoire !"} ]}
                                    >
                                        <InputNumber size="large" style={{ width: "100%" }} max={() => getMaxRetour()} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="px-2">
                                    <Form.Item
                                        label="Quantité Premier Choix"
                                        name="quantitePremierChoix"
                                        rules={[ {required :true , message : "Champ obligatoire !"} ]}
                                    >
                                        <InputNumber min={0} size="large" style={{ width: "100%" }} onChange={() => caculateDeuiemeChoix()} />
                                    </Form.Item>
                                </Col>
                                <Col span={12} className="px-2">
                                    <Form.Item
                                        label="Quantité Deuxieme Choix"
                                        name="quantiteDeuxiemeChoix"
                                        rules={[ {required :true , message : "Champ obligatoire !"} ]}
                                    >
                                        <InputNumber min={0} size="large" style={{ width: "100%" }} onChange={() => caculatePremierChoix()} />
                                    </Form.Item>
                                </Col>
                                
                            </Row>
                        </Modal>
                    </Form>
                </Card>
            </Spin>
        </>
    );
};
export default Confection;
    